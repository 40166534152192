@use "../../../library/breakpoints"
@use "../../../library/colors"
@use "../../../library/text"
@use "../../videoSticker"
@use "../../podcastSticker"
@use "../../podcastVisual"
@use "../../../../../templates/twig/_components/storyCard/__cover/storyCard__cover--square"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"
@use "include-media/dist/include-media"

=overlay
    height: 100%
    position: absolute
    right: 0
    top: 0

.mainHeadlines

    @include include-media.media("<min")
        &__row
            margin-top: 15px

    .thematicGroup
        margin-top: 1.25rem

    &__GroupList
        display: flex
        flex-direction: column
        justify-content: space-between
        gap: 0.9375rem // todo is it widely supported?
        @include include-media.media(">=min")
            flex-direction: row
            gap: 1rem // todo is it widely supported?
        @include include-media.media(">=full")
            gap: 1.875rem // todo is it widely supported?

    &__GroupItem
        flex: 1

.homeHeadlinesRow__main

    .storyCard--position-1:hover
        .storyCard
            &__titleLink
                color: white

    .storyCard
        margin-top: 0.9375rem

        @include include-media.media(">=min")
            height: 100%
            position: relative
            z-index: 10

        &__suptitle
            margin-bottom: 10px

        &__title
            margin-bottom: 12px
            @include include-media.media(">=min")
                @include text.line-clamp(5)

        &--position-1

            .storyCard
                padding-bottom: 0

                &__details
                    position: absolute
                    bottom: 0
                    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 67%, rgba(0, 0, 0, 0) 100%)
                    padding: 10% 15px 15px 15px
                    width: 100%
                    z-index: 2
                    @include include-media.media(">=min")
                        +overlay
                        width: 242px
                        padding: 10px 10px
                        background: initial
                    @include include-media.media(">=full")
                        +overlay
                        width: 330px
                        padding: 20px 20px 0
                        background: initial

                &__title
                    margin: 15px 0 15px 0
                    @include include-media.media(">=min")
                        margin: 0 0 10px 0
                    @include include-media.media(">=full")
                        margin: 0 0 15px 0

                &__lead
                    line-height: 1.25em
                    display: initial

                &__leadLink
                    @include text.line-clamp(4)

                &__cover
                    position: relative
                    margin-bottom: 0
                    padding-top: 120%

                    @include include-media.media(">=min")
                        width: calc(100% - 242px)
                        background-position: 0 50%
                        padding-top: initial
                        height: 311px

                    @include include-media.media(">=full")
                        width: calc(100% - 330px)
                        height: 420px

                &__publicationDates
                    margin-top: 1.5em

                &__blurredOverlay
                    display: none

                    @include include-media.media(">=min")
                        display: initial
                        height: 100%
                        position: absolute
                        right: 0
                        top: 0
                        overflow: hidden
                        background: hsla(0, 0%, 0%, 0.8)
                        width: 242px

                        img
                            filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="30" /></filter></svg>#filter')
                            filter: blur(30px)
                            transform: rotateY(-180deg) scale(1.1)
                            height: 100%
                            margin-left: -5px
                            opacity: 0.4

                    @include include-media.media(">=full")
                        width: 330px


            @include podcastVisual.podcastVisual--storycard-large
            .podcastEpisode__visual
                aspect-ratio: 1
                @include include-media.media(">min")
                    aspect-ratio: 3/2
