@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/fonts"
@use "../../../library/text"

.homeHeadlinesRow__collectionTitle
    position: absolute !important
    width: 100%
    padding: 0 15px
    height: 50px
    font-family: fonts.$fontVerlag
    font-weight: 400
    font-size: 1em
    line-height: 50px
    color: white
    background-color: rgba(30, 30, 30, 0.7)
    z-index: 10
    text-transform: uppercase
    @include text.line-clamp(1)
