@use "include-media/dist/include-media"
@use "../../videoSticker"
@use "../../commentSticker"
@use "../../podcastSticker"
@use "../../podcastVisual"
@use "../../../library/breakpoints"
@use "../../../../../templates/twig/_components/storyCard/__cover/storyCard__cover--square"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"

.homeRow--essential

    margin-bottom: 1.875rem

    .storyCard

        border-color: #CCCCCC

        &__details
            font-size: 1.875rem
            @include include-media.media(">=min")
                font-size: 1.4375rem
            @include include-media.media(">=full")
                font-size: 1.75rem

        &__suptitle
            font-size: (18 / 28) * 1em
            margin-bottom: 0.6em

        &__title
            line-height: 1.1
            margin-bottom: 0.6em

        &__titleLink--featuredAuthor
            font-size: 0.94em
            font-weight: 600

@include include-media.media("<min")
    .homeRow--essential
        .thematicGroup__listItem
            &:nth-child(2),
            &:nth-child(3)
                .storyCard

                    &__details
                        display: inline-block
                        vertical-align: top
                        width: calc(100% - 100px - 20px)
                        @include include-media.media(">=min")
                            width: calc(100% - 67px - 16px)
                        @include include-media.media(">=full")
                            width: calc(100% - 80px - 20px)

                    &__suptitle
                        font-size: 0.875rem
                        font-weight: 300

                        &--featuredAuthor
                            font-size: 0.875rem

                        @include include-media.media(">=min")
                            font-size: 0.9375rem
                            font-weight: 300

                            &--featuredAuthor
                                font-size: 0.9375rem

                        @include include-media.media(">=full")
                            font-size: 1rem

                            &--featuredAuthor
                                font-size: 0.9375rem

                    &__title

                        font-size: 1.125rem
                        font-weight: 900
                        line-height: 1.22em

                        &--featuredAuthor
                            font-size: 1.0625rem

                        @include include-media.media(">=min")
                            font-size: 1.125rem
                            font-weight: 600

                            &--featuredAuthor
                                font-size: 1.0625rem

                        @include include-media.media(">=full")
                            font-size: 1.25rem

                            &--featuredAuthor
                                font-size: 1.1875rem

                    &__cover
                        @include storyCard__cover--square.style

                    &__image
                        @include storyCard__image--square.style

                @include podcastVisual.podcastVisual--storycard-small

                @include include-media.media(">=min", "<full")
                    @include videoSticker.video-sticker-small
                    @include commentSticker.comment-sticker-small
                    @include podcastSticker.podcast-sticker-small
