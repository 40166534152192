@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/fonts"
@use "../../../library/text"

.homeHeadlinesRow--displayMode-smallBox

    margin-top: 0.3125rem
    display: grid

    .subHeader__link
        display: block
        a:after
            border-color: #F2F2F2
            right: 1.25rem

    .storyCard__blurredOverlay
        display: none !important

    .mainHeadlines__list
        background-color: #2D2D2D

    .mainHeadlines__row:nth-child(1)
        .homeHeadlinesRow
            &__listItem
                margin-top: 0 !important

    .mainHeadlines__row:nth-child(2),
    .mainHeadlines__row:nth-child(3)
        width: 100% !important
        padding: 0.9375rem 0.9375rem 0 0.9375rem

        .mainHeadlines__GroupList
            gap: 1rem !important

        @include include-media.media(">=min")
            padding: 0
            margin-top: 0.9375rem !important
            width: 709px !important
            .mainHeadlines__GroupList
                gap: 1rem !important
        @include include-media.media(">=full")
            width: 960px !important
            .mainHeadlines__GroupList
                gap: 1.875rem !important

        .storyCard
            margin: 0
            border-color: #dedede
            &__cover
                display: block

    .homeHeadlinesRow
        &__main
            margin-top: 0.9375rem

        &__collectionTitle
            font-size: 1.25rem
            text-transform: none
            font-weight: 400
            color: #F2F2F2
            background-color: #2D2D2D !important
            display: block !important
            position: relative !important
            left: 0 !important
            padding: 0 0 0 1rem
            width: calc(100% - 1.875rem)
            margin: 0 0.9375rem

            @include include-media.media(">=min")
                font-size: 1.5rem
                width: 709px
                margin: 0
            @include include-media.media(">=full")
                font-size: 1.625rem
                width: 960px

    @include include-media.media(">=min")
        width: 1024px

        .mainHeadlines__list
            width: 709px
            grid-template-columns: 468px 237px

        .homeHeadlinesRow
            &__listItem,
            &__listItem:nth-child(2),
            &__listItem:nth-child(3),
            &__listItem:nth-child(4)
                width: 217px

    @include include-media.media(">=full")

        width: 1290px

        .mainHeadlines__list
            width: 960px
            grid-template-columns: 630px 320px

        .homeHeadlinesRow
            &__listItem,
            &__listItem:nth-child(2),
            &__listItem:nth-child(3),
            &__listItem:nth-child(4)
                width: 300px

    .homeHeadlines--displayMode-smallBox
        .storyCard

            border-color: #404040

            .storyCard__suptitle,
            .storyCard__title,
            .storyCard__lead
                color: #F2F2F2

            .storyCard__suptitle
                font-size: 0.875rem
                @include include-media.media(">=min")
                    font-size: 0.9375rem
                @include include-media.media(">=full")
                    font-size: 1.0rem

            .storyCard__title
                font-size: 1.125rem
                @include include-media.media(">=min")
                    font-size: 1.0625rem
                @include include-media.media(">=full")
                    font-size: 1.375rem

            &--position-1
                .storyCard__details
                    margin: 1rem 0
                    border-right: 1px solid #404040
                    padding: 0 1rem
                    top: 0

                    @include include-media.media(">=min")
                        width: 468px

                    @include include-media.media(">=full")
                        width: 630px

                .storyCard__cover

                    height: auto

                    &:after
                        display: none

                    @include include-media.media(">=min")
                        width: 468px

                    @include include-media.media(">=full")
                        width: 630px

                .storyCard__image
                    height: 100%
                    width: 100%

                .storyCard__suptitle
                    font-size: 1.25rem
                    @include include-media.media(">=min")
                        font-size: 1.125rem
                    @include include-media.media(">=full")
                        font-size: 1.25rem

                .storyCard__title
                    font-size: 1.875rem
                    @include include-media.media(">=min")
                        font-size: 2.0rem
                    @include include-media.media(">=full")
                        font-size: 2.25rem

                .storyCard__lead
                    @include include-media.media("<min")
                        display: none !important
                    @include include-media.media(">=min")
                        font-size: 1.0rem
                    @include include-media.media(">=full")
                        font-size: 1.375rem

                .storyCard__leadLink
                    @include include-media.media(">=min")
                        @include text.line-clamp(3)

                .podcastSticker__badge
                    margin-top: 0
                    margin-left: 1rem
