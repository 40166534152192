@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/fonts"
@use "../../../library/text"

.homeHeadlinesRow--displayMode-grouped

    .subHeader__link
        font-size: inherit
        font-weight: inherit
        text-transform: inherit
        color: inherit
        margin: 0
        padding: 0
        border: none
        a:after
            border-color: #FFF
            right: 1.25rem
        @include include-media.media(">=min")
            display: inline
            a:after
                right: -3.125rem

    .homeHeadlines
        @include include-media.media(">=min")
            justify-content: space-between

    .homeHeadlinesRow
        &__main
            width: 100%

        &__collectionTitle
            color: #FFF
            font-size: 1.25rem
            font-weight: 400
            text-transform: none
            padding: 0 0 0 1rem
            @include include-media.media(">=min")
                font-size: 1.5rem
                width: 1024px
                left: calc((100% - 1024px) / 2)
                position: absolute
            @include include-media.media(">=full")
                font-size: 1.625rem
                line-height: 66px
                width: 1290px
                height: 66px
                left: calc((100% - 1290px) / 2)
                padding: 0 0 0 17px

        &__collectionTitleLink
            color: inherit
            font-size: inherit

    @include include-media.media(">=min")
        width: 100%
        display: initial


        .homeHeadlinesRow

            &__main
                padding-right: 0
                margin: 35px 0 26px 0

            &__list
                display: grid
                grid-template-columns: 630px auto
                grid-template-rows: auto auto auto auto auto auto auto
                padding: 0 calc((100% - 1024px) / 2) 0 calc((100% - 1024px) / 2)

            &__listItem,
            &__listItem:nth-child(2),
            &__listItem:nth-child(3),
            &__listItem:nth-child(4)
                width: 395px
                flex-basis: initial
                margin-bottom: 0
                grid-column: 2

            &__listItem:nth-child(2)
                margin-top: 50px

            &__listItem:first-child
                grid-column: 1
                grid-row: 1 / 8
                width: breakpoints.$page-min-width
                height: 100%
                left: calc((100% - 1024px) / 2)
                z-index: 2
                margin-bottom: initial

        .pageBlock--emplacement-homePageMainHeadlines
            .storyCard--position-1
                .videoSticker
                    &__badge
                        margin-top: 0
                        margin-left: 0
                .podcastSticker
                    &__badge
                        margin-top: 0
                        margin-left: 0

    @include include-media.media(">=full")

        .homeHeadlinesRow

            &__list
                grid-template-columns: 410px auto
                padding: 0 calc((100% - 1290px) / 2) 0 calc((100% - 1290px) / 2)

            &__listItem,
            &__listItem:nth-child(2),
            &__listItem:nth-child(3),
            &__listItem:nth-child(4)
                width: 410px

            &__listItem:nth-child(2)
                margin-top: 65px

            &__listItem:first-child
                width: breakpoints.$page-full-width
                left: calc((100% - 1290px) / 2)

        .pageBlock--emplacement-homePageMainHeadlines
            .storyCard--position-1
                .videoSticker
                    &__badge
                        margin-left: 17px
                .podcastSticker
                    &__badge
                        margin-left: 17px
