@use "../../../../../templates/twig/_components/storyCard/__cover/storyCard__cover--square"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"
@use "include-media/dist/include-media"
@use "../../videoSticker"
@use "../../commentSticker"
@use "../../podcastSticker"
@use "../../podcastVisual"
.homeRow--mainHeadlines-doNotMiss

    display: none
    @include include-media.media(">=min")
        display: block

    .header
        border-top: 4px solid black

    .storyCard

        &__details
            display: inline-block
            vertical-align: top
            width: calc(100% - 100px - 20px)
            @include include-media.media(">=min")
                width: calc(100% - 67px - 16px)
            @include include-media.media(">=full")
                width: calc(100% - 80px - 20px)

        &__suptitle

            font-weight: 300
            line-height: normal !important
            margin-bottom: 10px

            @include include-media.media(">=min")
                font-size: 0.8125rem

                &--featuredAuthor
                    font-size: 0.75rem

            @include include-media.media(">=full")
                font-size: 0.875rem

                &--featuredAuthor
                    font-size: 0.8125rem
        &__title

            font-weight: 600
            line-height: normal !important
            margin-bottom: 12px

            @include include-media.media(">=min")
                font-size: 0.9375rem

                &--featuredAuthor
                    font-size: 0.875rem

            @include include-media.media(">=full")
                font-size: 1.0rem

                &--featuredAuthor
                    font-size: 0.9375rem
        &__cover
            @include storyCard__cover--square.style

        &__image
            @include include-media.media(">=min")
                @include storyCard__image--square.style

        &__publicationDates
            @include include-media.media(">=min")
                display: none

        @include include-media.media(">=min", "<full")
            @include videoSticker.video-sticker-small
            @include commentSticker.comment-sticker-small
            @include podcastSticker.podcast-sticker-small

        @include podcastVisual.podcastVisual--storycard-small
